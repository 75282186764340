import React from 'react'
import "./CareerPage.css"
import Footer from "../Footer/Footer";
function CareerPage() {
  return (
    <>
      <section className="career-page">
        <h2>Currently there are no opening positions with kkreative pvt ltd</h2>
      </section>
      <Footer />
    </>
  );
}

export default CareerPage
